* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
body {
  position: relative;
  min-height: 100vh;
}
body::after {
  content: '';
  display: block;
  height: 120px; /* Set same as footer's height */
}

a {
  text-decoration: none;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
}

.inputForm {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card-container {
  padding: 1rem 0;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}
@media (min-width: 768px) {
  .card-container {
    padding: 1rem;
  }

  body::after {
    content: '';
    display: block;
    height: 50px; /* Set same as footer's height */
  }
  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
  }
}
